import { CallSplit, Remove } from "@mui/icons-material";
import { Stack, Autocomplete, TextField, IconButton } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Dimension,
  DimensionType,
  FilterOption,
  Operation,
  Value,
} from "../../../models/Explorer";
import { useExplorerStore } from "../../../data/ExplorerStore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface Props {
  metricGroupIndex: number;
  index: number;
  dimensionType: DimensionType;
}

export default function DimensionSelect({
  metricGroupIndex,
  index,
  dimensionType,
}: Props) {
  const dimensionsOfType = useExplorerStore(
    ({ metricGroups }) => metricGroups[metricGroupIndex][dimensionType].length
  );
  const dimension = useExplorerStore(
    ({ metricGroups }) => metricGroups[metricGroupIndex][dimensionType][index]
  );
  const updateDimension = useExplorerStore(
    ({ updateDimension }) =>
      (dimension: Dimension) =>
        updateDimension(metricGroupIndex, dimensionType, index, dimension)
  );
  const removeDimension = useExplorerStore(
    ({ removeDimension }) =>
      () =>
        removeDimension(metricGroupIndex, dimensionType, index)
  );
  const filters = useExplorerStore(({ filters }) => filters);
  const showSplits =
    dimensionType === "filters" || (!!dimension.params && dimension.params > 0);
  const getFilterOption = useCallback(
    (
      filterName: string | undefined = dimension.name?.value
    ): FilterOption | undefined => {
      if (!filters || !filterName) {
        return undefined;
      }
      return filters[filterName];
    },
    [dimension.name, filters]
  );

  const [filterOption, setFilterOption] = useState<FilterOption | undefined>(
    getFilterOption()
  );

  useEffect(() => {
    const newFilterOption = getFilterOption();
    setFilterOption(newFilterOption);
  }, [getFilterOption]);

  if (!filters) return null;

  const onFilterChange = (
    event: ChangeEvent<unknown>,
    value: string | null
  ) => {
    const dimensionForUpdate = Object.assign({}, dimension);
    dimensionForUpdate.name = {
      value: value ?? "",
      label: value ?? "",
      index: 0,
    };
    const newFilterOption = getFilterOption(value ?? undefined);
    setFilterOption(newFilterOption);
    if (!newFilterOption?.ops.some((op) => op.value === dimension.op?.value)) {
      dimensionForUpdate.op = newFilterOption?.ops[0];
    }
    const values = dimensionForUpdate.values;
    let applicableValues = Array.isArray(values) ? values.slice() : [];
    if (Array.isArray(applicableValues)) {
      applicableValues = applicableValues.filter((v) =>
        newFilterOption?.values.some((fov) => fov.value === v.value)
      );
    }
    dimensionForUpdate.values = applicableValues;
    updateDimension(dimensionForUpdate);
  };

  const onOpsChange = (
    event: ChangeEvent<unknown>,
    value: Operation | null
  ) => {
    const dimensionForUpdate = Object.assign({}, dimension);
    dimensionForUpdate.op = value ?? undefined;
    updateDimension(dimensionForUpdate);
  };

  const splitDimension = () => {
    const dimensionForUpdate = Object.assign({}, dimension);
    dimensionForUpdate.params = 1;
    updateDimension(dimensionForUpdate);
  };

  return (
    <>
      <Stack direction="row" mt={2} spacing={1} alignItems="center">
        <Autocomplete
          onChange={onFilterChange}
          id="filterSelect"
          options={[""].concat(Object.keys(filters))}
          value={dimension.name?.value ?? ""}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Select" />
          )}
          sx={{ width: "200px" }}
          autoComplete
          autoSelect
          disableClearable={
            index > 0 || dimensionsOfType > 1 || dimension.name?.value === ""
          }
        />

        {!!dimension.name && !!filterOption && showSplits && (
          <Autocomplete
            onChange={onOpsChange}
            id="opSelect"
            options={filterOption.ops}
            value={dimension.op ?? filterOption?.ops[0]}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Select" />
            )}
            isOptionEqualToValue={(option, value) =>
              value.value === "" || option.value === value.value
            }
            disableClearable
            sx={{ width: "100px" }}
            autoSelect
          />
        )}

        {!!dimension.name &&
          !!filterOption &&
          showSplits &&
          (filterOption.type === "values" ? (
            <MultiValueSelect
              filterOption={filterOption}
              dimension={dimension}
              updateDimension={updateDimension}
            />
          ) : filterOption.type === "date" ? (
            <DateValueSelect
              filterOption={filterOption}
              dimension={dimension}
              updateDimension={updateDimension}
            />
          ) : (
            <NumberValueSelect
              filterOption={filterOption}
              dimension={dimension}
              updateDimension={updateDimension}
            />
          ))}

        {!!dimension.name && !!filterOption && !showSplits && (
          <IconButton onClick={splitDimension}>
            <CallSplit />
          </IconButton>
        )}
        {index > 0 || dimensionsOfType > 1 ? (
          <IconButton onClick={removeDimension}>
            <Remove />
          </IconButton>
        ) : (
          // include spacer for the first filter so it can't be removed but aligns with other filters
          <IconButton disabled sx={{ opacity: 0 }}>
            <Remove />
          </IconButton>
        )}
      </Stack>
    </>
  );
}

interface ValueSelectProps {
  dimension: Dimension;
  updateDimension: (d: Dimension) => void;
  filterOption: FilterOption;
}

function MultiValueSelect({
  dimension,
  updateDimension,
  filterOption,
}: ValueSelectProps) {
  const onChange = (event: ChangeEvent<unknown>, value: Value[] | null) => {
    const dimensionForUpdate = Object.assign({}, dimension);
    dimensionForUpdate.values = value ?? undefined;
    updateDimension(dimensionForUpdate);
  };
  const filters = useExplorerStore(({ filters }) => filters);

  const isDependentFilter = useMemo(() => dimension.op && (dimension.op?.value === "as" || dimension.op?.value === "not as"), [dimension])

  const filterOptions = useMemo(() => {
    if (!isDependentFilter || !filters) {
      return filterOption.values
      .slice()
      .sort((a, b) => (a.label < b.label ? 1 : -1))
    } else {
      return Object.keys(filters).map(f => ({
        "label": f, "value": f
      }))
    }
  }, [isDependentFilter, filterOption.values, filters])

  if (!Array.isArray(dimension.values)) return null;

  return (
    <Autocomplete
      onChange={onChange}
      id="valuesSelect"
      multiple
      options={filterOptions}
      value={(dimension.values as Value[]) ?? []}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      sx={{ minWidth: "200px" }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
}

function DateValueSelect({
  dimension,
  updateDimension,
  filterOption,
}: ValueSelectProps) {
  const valueAsDate =
    typeof dimension.values === "string" ? dayjs(dimension.values) : dayjs();

  const onChange = (
    value: dayjs.Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => {
    const dimensionForUpdate = Object.assign({}, dimension);
    const formattedDate = value?.format("YYYYMMDD");
    dimensionForUpdate.values = value?.format(formattedDate);
    updateDimension(dimensionForUpdate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={valueAsDate}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

function NumberValueSelect({
  dimension,
  updateDimension,
  filterOption,
}: ValueSelectProps) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const dimensionForUpdate = Object.assign({}, dimension);
    dimensionForUpdate.values = [
      { label: event.target.value, value: event.target.value },
    ];
    dimensionForUpdate.values = event.target.valueAsNumber;
    updateDimension(dimensionForUpdate);
  };

  return (
    <TextField
      value={dimension.values}
      type="number"
      onChange={onChange}
      placeholder="Enter value"
      id="valuesSelect"
    />
  );
}
