import { useEffect } from "react";
import {
  Container,
  Stack,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { QueryStatus, useExplorerStore } from "../../../data/ExplorerStore";
import QueryBuilder from "./QueryBuilder";
import { Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import ExplorerDataGrid from "../../Elements/ExplorerDataGrid/ExplorerDataGrid";
import { HelpOutlineOutlined } from "@mui/icons-material";

export default function Explorer() {
  const data = useExplorerStore(({ data }) => data);
  const explorerStatus = useExplorerStore(
    ({ explorerStatus }) => explorerStatus
  );
  const initExplorer = useExplorerStore(({ initExplorer }) => initExplorer);
  const isLoading = useExplorerStore(
    ({ queryStatus }) => queryStatus === QueryStatus.Requesting || queryStatus === QueryStatus.None
  );
  const savedQuery = useExplorerStore(({ savedQuery }) => savedQuery);
  const [error, clearError] = useExplorerStore(({ error, clearError }) => [error, clearError]);
  const location = useLocation()

  useEffect(() => {
    if (explorerStatus === QueryStatus.None) {
      initExplorer(location.hash.substring(1));
    }
  }, [explorerStatus, initExplorer, location]);


  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        my={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="top" spacing={0.5}>
          <Typography variant="h2" sx={{ textTransform: "capitalize" }}>
            {savedQuery?.NAME ?? ""}
          </Typography>
          {savedQuery?.DESCRIPTION && (
            <Tooltip title={savedQuery?.DESCRIPTION || ""}>
              <HelpOutlineOutlined fontSize="small" />
            </Tooltip>
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="flex-end"
        >
          <QueryBuilder />
        </Stack>
      </Stack>
      {error && (
        <Stack direction="row" alignItems="center">
          <IconButton size="small" color="error" onClick={clearError}>
            <Close fontSize="small" />
          </IconButton>
          <Typography color="error" fontWeight={500}>
            {error.message}
          </Typography>
        </Stack>
      )}
      <ExplorerDataGrid data={data} loading={isLoading} />
    </Container>
  );
}
