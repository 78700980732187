import produce from "immer";

export interface SavedQuery {
  ID: string;
  NAME: string;
  QUERY: string;
  USER_ID: string;
  SAVE_TYPE: string;
  LAST_SAVED: string;
  USER_NAME: string;
  IS_OWNER: boolean;
  DESCRIPTION: string;
}

export interface SavedQueryForm {
  queryName: string;
  description: string;
  saveType: 'public' | 'private' | 'archived';
}

export type EditQueryForm = SavedQueryForm & {
  editQuery?: string;
  editQueryName?: string;
  editQueryId: string;
}

export interface MetricGroup {
  metrics?: Metric[];
  dimensions: Dimension[];
  filters: Dimension[];
  ratio: Metric | null;
}

interface MetricGroupPreset {
  label: string;
  metrics: Metric[];
}

export type Metric = {
  index?: number;
  value: string;
  label: string;
};

export type DimensionType = "dimensions" | "filters";

export type FilterResponse = {
  [filterName: string]: FilterOption;
};

export interface Dimension {
  name?: Metric;
  op?: Operation;
  values?: Value[] | number | string;
  params?: number;
}

export interface FilterOption {
  ops: Operation[];
  type: "values" | "number" | "array_uuid_bd" | "date";
  values: Value[];
}

export type Operation = {
  label: string;
  value: string;
};

export type Value = {
  label: string;
  value: string;
};

export interface ExplorerError {
  message: string;
}

/* ******************* */
/* BLANKS AND DEFAULTS */
/* ******************* */

export const blankDimension = {
  name: undefined,
  op: undefined,
  values: undefined,
  params: 0,
};

export const defaultQueryGroup = {
  dimensions: [
    {
      name: {
        value: "TeamOffense",
        label: "TeamOffense",
      },
      op: {
        label: "in",
        value: "in",
      },
    },
  ],
  filters: [
    {
      name: {
        value: "Season",
        label: "Season",
      },
      op: {
        label: "in",
        value: "in",
      },
      values: [
        {
          label: "2024",
          value: "2024",
        },
      ],
    },
    {
      name: {
        value: "SeasonType",
        label: "SeasonType",
      },
      op: {
        label: "in",
        value: "in",
      },
      values: [
        {
          label: "regular season",
          value: "regular season",
        },
      ],
    },
    {
      name: {
        value: "Garbage",
        label: "Garbage",
      },
      op: {
        label: "in",
        value: "in",
      },
      values: [
        {
          label: "False",
          value: "False",
        },
      ],
    },
  ],
  ratio: null,
};

const possMetric = { value: "Poss", label: "Poss" };

const efficiencyMetrics: MetricGroupPreset = {
  label: "Efficiency",
  metrics: [
    { value: "aPPP", label: "aPPP" },
    { value: "PPP", label: "PPP" },
    { value: "ePPP", label: "ePPP" },
  ],
};

const per100EfficiencyMetrics = {
  label: "per 100",
  metrics: [
    { value: "aPP100", label: "aPP100" },
    { value: "PP100", label: "PP100" },
    { value: "ePP100", label: "ePP100" },
  ],
};

const fourFactorsMetrics = {
  label: "Four Factors",
  metrics: [
    { value: "TS%", label: "TS%" },
    { value: "ORB%", label: "ORB%" },
    { value: "TOV%", label: "TOV%" },
    { value: "FTRate", label: "FTRate" },
  ],
};

const shotProfileMetrics = {
  label: "Shot Profile",
  metrics: [
    { value: "RimRate", label: "RimRate" },
    { value: "MidRate", label: "MidRate" },
    { value: "3Rate", label: "3Rate" },
    { value: "RimTS%", label: "RimTS%" },
    { value: "MidTS%", label: "MidTS%" },
    { value: "3TS%", label: "3TS%" },
  ],
};

const individualOffensiveMetrics = {
  label: "Ind. Offense",
  metrics: [
    { value: "iUSG%", label: "iUSG%" },
    { value: "iAST%", label: "iAST%" },
    { value: "iTOV%", label: "iTOV%" },
    { value: "iAST/TOV", label: "iAST/TOV" },
    { value: "iORB%", label: "iORB%" },
    { value: "iTS%", label: "iTS%" },
    { value: "iFG%", label: "iFG%" },
    { value: "iFT%", label: "iFT%" },
    { value: "iFTRate", label: "iFTRate" },
  ],
}

const individualShotProfile = {
  label: "Ind. Shot Profile",
  metrics: [
    { value: "iRimRate", label: "iRimRate" },
    { value: "iMidRate", label: "iMidRate" },
    { value: "i3Rate", label: "i3Rate" },
    { value: "iRimFG%", label: "iRimFG%" },
    { value: "iMidFG%", label: "iMidFG%" },
    { value: "i3FG%", label: "i3FG%" },
  ],
}

const individualDefensiveMetrics = {
  label: "Ind. Defense",
  metrics: [
    { value: "iDRB%", label: "iDRB%" },
    { value: "iSTL%", label: "iSTL%" },
    { value: "iBLK%", label: "iBLK%" },
    { value: "iFoulRate", label: "iFoulRate" },
  ],
}

export const metricGroupPresets = [
  efficiencyMetrics,
  per100EfficiencyMetrics,
  fourFactorsMetrics,
  shotProfileMetrics,
  individualOffensiveMetrics,
  individualShotProfile,
  individualDefensiveMetrics,
];

export const blankMetricGroup = {
  metrics: [possMetric],
  dimensions: [{ params: 0 }],
  filters: [{ params: 0 }],
  ratio: null,
};

export const defaultMetricGroup = {
  dimensions: [{ params: 0 }],
  filters: [{ params: 0 }],
  ratio: null,
  metrics: [
    { value: "Video", label: "Video" },
    { value: "ShotChart", label: "ShotChart" },
    possMetric,
    ...efficiencyMetrics.metrics,
    ...fourFactorsMetrics.metrics,
    ...shotProfileMetrics.metrics,
  ],
};

export const sortQuery = produce((draft: MetricGroup[]) => {
  draft.forEach((mg, i) => {
    draft.splice(i, 1, sortMetricGroup(mg))
  })
})

const sortMetricGroup = produce((draft: MetricGroup) => {
  if (draft.metrics && Array.isArray(draft.metrics)) {
    draft.metrics.sort((a, b) => a.value > b.value ? 1 : -1)
  }

  const dimensionSort = (a: Dimension, b: Dimension) => {
    if (["Season", "SeasonType", "Garbage"].includes(a.name?.value ?? "")) {
      return -1;
    }
    return (a.name?.value ?? "") > (b.name?.value ?? "") ? 1 : -1
  }

  draft.filters.sort(dimensionSort)
  draft.dimensions.sort(dimensionSort)
})
