import produce from "immer";
import create from "zustand";
import {
  blankDimension,
  blankMetricGroup,
  defaultMetricGroup,
  defaultQueryGroup,
  Dimension,
  DimensionType,
  ExplorerError,
  FilterResponse,
  MetricGroup,
  SavedQuery,
  SavedQueryForm,
  sortQuery,
} from "../models/Explorer";
import { TableData } from "../models/TableData";
import {
  executeQuery,
  getFilters,
  getMetrics,
  loadQueries,
  loadQuery,
  saveQuery,
  SavedQueryRequest,
  archiveQuery,
  claimQuery,
  editQuery
} from "../util/Requests";
import { CacheKey, readFromCache, writeToCache } from "./Cache";
import { Auth } from 'aws-amplify';

export enum QueryStatus {
  None = 0,
  Requesting = 1,
  Complete = 2,
  Failed = 3,
}

interface ExplorerState {
  filters: FilterResponse | null;
  metrics: string[];
  metricGroups: MetricGroup[];
  // currentQueryString: string;
  data: TableData;
  savedQueries: SavedQuery[][];
  queryStatus: QueryStatus;
  explorerStatus: QueryStatus;
  savedQueriesStatus: QueryStatus;
  savedQuery?: SavedQuery;
  pendingController?: AbortController;
  error?: ExplorerError;
}

async function groupSavedQueries(queries: SavedQuery[]): Promise<SavedQuery[][]> {
  const token = (await Auth.currentSession()).getIdToken()
  const userId = token.payload.sub
  const groupedQueries: SavedQuery[][] = [];
  const queriesByUserId: { [key: string]: SavedQuery[] } = {};

  queries.forEach((query: SavedQuery) => {
    if (!queriesByUserId[query.USER_ID]) {
      queriesByUserId[query.USER_ID] = [];
    }
    query = Object.assign(query, { IS_OWNER: query.USER_ID === userId });
    queriesByUserId[query.USER_ID].push(query);
  });

  for (const userId in queriesByUserId) {
    groupedQueries.push(queriesByUserId[userId]);
  }

  groupedQueries.sort((a, b) => {
    if (a[0].USER_ID === userId || b[0].USER_ID === "no owner") {
      return -1;
    }
    if (a[0].USER_ID === "no owner" || b[0].USER_ID === userId) {
      return 1;
    }
    else return a[0].USER_ID.localeCompare(b[0].USER_ID);
  });

  return groupedQueries;
}

interface ExplorerActions {
  getCurrentQueryString: () => string;
  updateMetricGroup: (
    metricGroupIndex: number,
    metricGroup: MetricGroup
  ) => void;
  updateDimension: (
    metricGroupIndex: number,
    dimensionType: DimensionType,
    dimensionIndex: number,
    dimension: Dimension
  ) => void;
  execQuery: (queryId?: string) => void;
  initExplorer: (hash?: string) => void;
  addMetricGroup: () => void;
  addDimension: (
    metricGroupIndex: number,
    dimensionType: DimensionType
  ) => void;
  removeDimension: (
    metricGroupIndex: number,
    dimensionType: DimensionType,
    dimensionIndex: number
  ) => void;
  loadSavedQuery: (id: string) => void;
  saveQuery: (queryForm: SavedQueryForm) => Promise<any>;
  editQuery: (queryId: string, queryForm: SavedQueryForm) => void;
  archiveQuery: (queryId: string) => void;
  claimQuery: (queryId: string) => void;
  clearError: () => void;
}

export const useExplorerStore = create<ExplorerState & ExplorerActions>(
  (set, get) => ({
    /* STATE */
    filters: null,
    metrics: [],
    metricGroups: [defaultQueryGroup, defaultMetricGroup],
    // currentQueryString: (),
    data: [],
    savedQueries: [],
    queryStatus: QueryStatus.None,
    explorerStatus: QueryStatus.None,
    savedQueriesStatus: QueryStatus.None,
    queryName: "",

    /* ACTIONS */
    getCurrentQueryString: () => {
      const { metricGroups } = get();
      return JSON.stringify(sortQuery(metricGroups));
    },

    updateMetricGroup: (metricGroupIndex: number, metricGroup: MetricGroup) => {
      set(
        produce((draft) => {
          draft.metricGroups.splice(metricGroupIndex, 1, metricGroup);
        })
      );
    },

    addMetricGroup: () => {
      set(
        produce((draft) => {
          draft.metricGroups.push(blankMetricGroup);
        })
      );
    },

    execQuery: (queryId) => {
      const { metricGroups: query, pendingController } = get();
      if (pendingController) {
        pendingController.abort();
      }
      const controller = new AbortController();
      set({
        queryStatus: QueryStatus.Requesting,
        pendingController: controller,
        error: undefined,
      });
      const sortedQuery = sortQuery(query);
      executeQuery(sortedQuery, queryId)
        .then((response) => {
          if (controller.signal.aborted) return;
          let data = response.data.data
            ? JSON.parse(response.data.data)
            : response.data;
          const savedQuery = JSON.parse(response.data.saved_query);
          set({
            data: data,
            queryStatus: QueryStatus.Complete,
            savedQuery: savedQuery,
            pendingController: undefined,
          });
        })
        .catch((e) => {
          console.log("error executing query: ", e);
          set(
            produce((draft) => {
              draft.queryStatus = QueryStatus.Failed;
              draft.error = { message: `Error executing query: ${e.message}` };
              draft.data = [];
            })
          );
        });
    },

    initExplorer: async (hash) => {
      set({
        explorerStatus: QueryStatus.Requesting,
        savedQueriesStatus: QueryStatus.Requesting,
      });
      readFromCache(CacheKey.Filters).then((cachedFilters) => {
        if (cachedFilters) {
          set({ filters: cachedFilters as FilterResponse });
        }
      });
      readFromCache(CacheKey.Metrics).then((cachedMetrics) => {
        if (cachedMetrics) {
          set({ metrics: cachedMetrics as string[] });
        }
      });

      Promise.all([getFilters(), getMetrics(), loadQueries()])
        .then(
          async ([
            { data: filters },
            { data: metrics },
            { data: savedQueries },
          ]) => {
            writeToCache(CacheKey.Filters, filters);
            writeToCache(CacheKey.Metrics, metrics);
            const groupedQueries = await groupSavedQueries(savedQueries);
            console.log("grouped queries: ", groupedQueries);
            set({
              metrics,
              filters,
              savedQueries: groupedQueries,
              explorerStatus: QueryStatus.Complete,
              savedQueriesStatus: QueryStatus.Complete,
            });
          }
        )
        .catch(() => {
          set({ explorerStatus: QueryStatus.Failed });
        });

      if (hash) {
        loadQuery(hash)
          .then(async ({ data }) => {
            set({ metricGroups: sortQuery(JSON.parse(data.QUERY)) });
          })
          .catch((e) => {
            const msg = e.response?.data?.message || e.message;
            console.log("load query failed with error: ", msg);
            set(
              produce((draft) => {
                draft.queryStatus = QueryStatus.Failed;
                draft.error = { message: `Error loading query: ${msg}` };
                draft.data = [];
              })
            );
          });
      }
      get().execQuery(hash);
    },

    updateDimension: (
      metricGroupIndex: number,
      dimensionType: DimensionType,
      dimensionIndex: number,
      dimension: Dimension
    ) => {
      set(
        produce((draft) => {
          draft.metricGroups[metricGroupIndex][dimensionType][dimensionIndex] =
            dimension;
        })
      );
    },

    addDimension: (metricGroupIndex: number, dimensionType: DimensionType) => {
      set(
        produce((draft) => {
          draft.metricGroups[metricGroupIndex][dimensionType].push(
            blankDimension
          );
        })
      );
    },

    removeDimension: (
      metricGroupIndex: number,
      dimensionType: DimensionType,
      dimensionIndex: number
    ) => {
      set(
        produce((draft) => {
          draft.metricGroups[metricGroupIndex][dimensionType].splice(
            dimensionIndex,
            1
          );
        })
      );
    },

    loadSavedQuery: async (id: string) => {
      set({
        explorerStatus: QueryStatus.Requesting,
        savedQueriesStatus: QueryStatus.Requesting,
      });
      const savedQuery = (await loadQuery(id)).data;
      await set({
        metricGroups: JSON.parse(savedQuery.QUERY),
        savedQuery: savedQuery,
        savedQueriesStatus: QueryStatus.Complete,
      });
      await get().execQuery(id);
    },

    saveQuery: async (queryForm: SavedQueryForm) => {
      return new Promise<void>((resolve, reject) => {
        const q = get().metricGroups;
        const sortedQuery = sortQuery(q);
        const query: SavedQueryRequest = {
          name: queryForm.queryName,
          description: queryForm.description,
          saveType: queryForm.saveType,
          query: sortedQuery,
        };

        const sq = {
          NAME: query.name,
          DESCRIPTION: query.description,
          QUERY: JSON.stringify(query.query),
          USER_ID: "",
          USER_NAME: "",
          IS_OWNER: true,
          LAST_SAVED: "",
          SAVE_TYPE: query.saveType,
        };
        set({ savedQueriesStatus: QueryStatus.Requesting });

        saveQuery(query)
          .then((response) => {
            loadQueries().then(async ({ data }) => {
              const groupedQueries = await groupSavedQueries(data);
              set({
                savedQueries: groupedQueries,
                savedQueriesStatus: QueryStatus.Complete,
                savedQuery: Object.assign(sq, { ID: response.data.query_id }),
              });
              resolve();
            });
          })
          .catch((err) => {
            console.log("error saving query: ", err);
            set({
              error: {
                message: `Error saving query: ${
                  err.response?.data.message || err.message
                }`,
              },
              savedQueriesStatus: QueryStatus.Failed,
            });
            reject(err.response?.data.message || err.message);
          });
      });
    },

    editQuery: async (queryId: string, queryForm: SavedQueryForm) => {
      const query: SavedQueryRequest = {
        name: queryForm.queryName,
        description: queryForm.description,
        saveType: queryForm.saveType,
      };

      set({ savedQueriesStatus: QueryStatus.Requesting });
      editQuery(queryId, query)
        .then((response) => {
          loadQueries().then(async ({ data }) => {
            const groupedQueries = await groupSavedQueries(data);
            const sq = await get().savedQuery;
            if (sq && sq.ID === queryId) {
              sq.NAME = queryForm.queryName;
              sq.DESCRIPTION = queryForm.description;
              sq.SAVE_TYPE = queryForm.saveType;
            }
            set({
              savedQueries: groupedQueries,
              savedQueriesStatus: QueryStatus.Complete,
              savedQuery: sq,
            });
          });
        })
        .catch((err) => {
          console.log("error editing query: ", err);
          set({
            error: {
              message: `Error editing query: ${
                err.response?.data.message || err.message
              }`,
            },
            savedQueriesStatus: QueryStatus.Failed,
          });
        });
    },

    archiveQuery: async (queryId: string) => {
      set({ savedQueriesStatus: QueryStatus.Requesting });
      archiveQuery(queryId)
        .then((response) => {
          loadQueries().then(async ({ data }) => {
            const groupedQueries = await groupSavedQueries(data);
            const sq = await get().savedQuery;
            if (sq && sq.ID === queryId) {
              sq.NAME = "";
              sq.SAVE_TYPE = "archived";
            }
            set({
              savedQueries: groupedQueries,
              savedQueriesStatus: QueryStatus.Complete,
              savedQuery: sq,
            });
          });
        })
        .catch((err) => {
          console.log("error archiving query: ", err);
          set({
            error: {
              message: `Error archiving query: ${
                err.response?.data.message || err.message
              }`,
            },
            savedQueriesStatus: QueryStatus.Failed,
          });
        });
    },

    claimQuery: async (queryId: string) => {
      set({ savedQueriesStatus: QueryStatus.Requesting });
      claimQuery(queryId)
        .then((response) => {
          loadQueries().then(async ({ data }) => {
            const groupedQueries = await groupSavedQueries(data);
            set({
              savedQueries: groupedQueries,
              savedQueriesStatus: QueryStatus.Complete,
            });
          });
        })
        .catch((err) => {
          console.log("error claiming query: ", err);
          set({
            error: {
              message: `Error claiming query: ${
                err.response?.data.message || err.message
              }`,
            },
            savedQueriesStatus: QueryStatus.Failed,
          });
        });
    },

    clearError: () => {
      set({ error: undefined });
    },
  })
);
