import { TextField } from "@mui/material";
import "./styledTextField.scss";
import { Ref } from "react";

interface StyledTextFieldProps {
  id?: string;
  simple?: boolean;
  label?: string;
  placeholder: string;
  width?: "half" | "full";
  name?: string;
  value?: string | number | undefined | null;
  error?: boolean;
  disabled?: boolean;
  outlined?: boolean;
  type?: string;
  InputProps?: {};
  inputProps?: {};
  InputLabelProps?: {};
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialValue?: string;
  marginTop?: number | string;
  selectAllOnFocus?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  inputRef?: Ref<any>;
}

export const StyledTextField = ({
  id,
  simple = false,
  label,
  placeholder,
  width,
  name,
  value,
  error,
  disabled,
  outlined,
  type,
  InputProps,
  inputProps,
  onChange,
  marginTop,
  selectAllOnFocus = false,
  helperText,
  autoFocus,
  inputRef,
  ...props
}: StyledTextFieldProps) => {
  const mode =
    simple && !outlined
      ? "simple-field"
      : outlined && !simple
        ? "outlined-field"
        : simple && outlined
          ? "simple-outlined-field"
          : "textfield";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={["textfield--container", `textfield--${width}`].join(" ")}>
      <TextField
        fullWidth
        id={id}
        autoFocus={autoFocus}
        inputRef={inputRef}
        label={label}
        name={name}
        value={value}
        error={error}
        disabled={disabled}
        type={type}
        onChange={handleChange}
        variant="standard"
        placeholder={placeholder}
        className={mode}
        onFocus={
          selectAllOnFocus
            ? (event) => {
                event.target.select();
              }
            : undefined
        }
        sx={{
          "& .MuiInputBase-root": {
            marginTop,
          },
        }}
        helperText={helperText}
        {...props}
        inputProps={inputProps}
        InputLabelProps={{ shrink: true }}
        // slotProps={{
        //   input: InputProps,
        //   htmlInput: inputProps,
        //   inputLabel: { shrink: true },
        // }}
      />
    </div>
  );
};
