import "./queryBuilder.scss";
import { useState, ChangeEvent, SyntheticEvent, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Edit, Folder, Save } from "@mui/icons-material";
import { QueryStatus, useExplorerStore } from "../../../data/ExplorerStore";
import { EditQueryForm, SavedQueryForm } from "../../../models/Explorer";
import { SimpleModal } from "../../Elements/SimpleModal/SimpleModal";
import TabContent from "../../Elements/TabContent";
import QueryForm from "./QueryForm";
import MetricGroupForm from "./MetricGroupForm";
import { RichTreeViewPro } from "@mui/x-tree-view-pro";
import SavedQueryTreeItem, { SavedQueryTreeItemType } from "./SavedQueryTreeItem";
import { StyledTextField } from "../../Elements/StyledTextField/StyledTextField";
import { StyledTextArea } from "../../Elements/StyledTextArea/StyledTextArea";
import { StyledRadioButtonsGroup } from "../../Elements/StyledRadioButtons/StyledRadioButtonsGroup";
import { StyledRadioButton } from "../../Elements/StyledRadioButtons/StyledRadioButton";


export default function QueryBuilder() {
  const [openDialog, setOpenDialog] = useState<
    "queryBuilder" | "savedQueries" | "saveQuery" | "editQuery" | null
  >(null);
  const [currentTab, setCurrentTab] = useState<string>("query");
  const savedQuery = useExplorerStore(({ savedQuery }) => savedQuery);
  const metricGroupsLength = useExplorerStore(
    ({ metricGroups }) => metricGroups.length
  );
  const pseudoMetricGroupArray = Array.from(
    { length: metricGroupsLength },
    (x, i) => i
  );
  const addMetricGroup = useExplorerStore(({ addMetricGroup }) => () => {
    addMetricGroup();
    setCurrentTab(`metricGroup${metricGroupsLength}`);
  });
  const currentQueryString = useExplorerStore(({ getCurrentQueryString }) => getCurrentQueryString());
  const [formState, setFormState] = useState<SavedQueryForm>({
    queryName: "",
    description: "",
    saveType: "public",
  });
  const [editQueryForm, setEditQueryForm] = useState<EditQueryForm>({} as EditQueryForm);
  const openEditDialog = (queryForm: SavedQueryForm, editQueryForm: EditQueryForm) => {
    setFormState(queryForm);
    setEditQueryForm(editQueryForm);
    setOpenDialog("editQuery");
  }
  const [overwriteError, setOverwriteError] = useState<string | null>(null);
  const [confirmDialogState, setConfirmDialogState] = useState<boolean>(false);
  const resetDialogState = () => setConfirmDialogState(false);
  const [savedQueries, savedQueriesStatus] = useExplorerStore(({ savedQueries, savedQueriesStatus }) => [savedQueries, savedQueriesStatus]);
  const savedQueriesLoading = savedQueriesStatus === QueryStatus.Requesting
  const savedQueriesTree: SavedQueryTreeItemType[] = savedQueries.map((sqg) => ({
    id: sqg[0].USER_ID,
    itemId: sqg[0].USER_ID || "no owner",
    label: sqg[0].USER_NAME,
    type: "folder",
    savedQuery: null,
    orphan: sqg[0].USER_ID === "no owner",
    children: sqg.map((sq) => ({
      itemId: sq.ID,
      id: sq.ID,
      label: sq.NAME,
      type: "query",
      savedQuery: sq,
      orphan: sq.USER_ID === "no owner",
      handleClose: () => setOpenDialog(null),
      openEditDialog,
    })),
  }));
  const handleSaveQueryClose = () => {
    setOpenDialog(null);
    setFormState({ queryName: "", description: "", saveType: "public" });
    setEditQueryForm({} as EditQueryForm);
    setOverwriteError(null);
  }
  const saveQuery = useExplorerStore(({ saveQuery }) => async () => {
    await saveQuery(formState);
    handleSaveQueryClose();
  });
  const execQuery = useExplorerStore(({ execQuery }) => () => {
    setOpenDialog(null);
    execQuery();
  });
  const handleUpdate = useExplorerStore(({ editQuery }) => () => {
    editQuery(editQueryForm.editQueryId, formState);
    handleSaveQueryClose();
    setOpenDialog("savedQueries");
  });
  const handleOverwriteQuery = useExplorerStore(({ archiveQuery, saveQuery }) => async () => {
    saveQuery(formState).then(() => {
      resetDialogState()
      archiveQuery(editQueryForm.editQueryId);
      handleSaveQueryClose();
      setOpenDialog("savedQueries");
    }).catch((err) => {
      resetDialogState();
      setOverwriteError(`Failed to overwrite query: ${err}`);
    })
  });

  useEffect(() => {
    if (!savedQuery) return
    if (savedQuery?.ID) {
      window.history.pushState(null, "", `#${savedQuery.ID}`)
    } else {
      window.history.pushState(null, "", window.location.pathname)
    }
  }, [savedQuery])

  const queryBuilderActions = (
    <Button variant="outlined" onClick={execQuery}>
      Run
    </Button>
  );

  // Check if the current querybuilder string is different from the edit query string
  // For some reason, comparing the strings directly does not work, but parsing and re-stringifying does
  const queryStringChanged = useMemo(() => {
    const editQueryString = editQueryForm.editQuery ?? "{}";
    return JSON.stringify(JSON.parse(currentQueryString)) !== JSON.stringify(JSON.parse(editQueryString));
  }, [currentQueryString, editQueryForm.editQuery]);

  const saveEditActions =
    openDialog === "editQuery" ? (
      <>
        {queryStringChanged && <Button variant="outlined" onClick={() => setConfirmDialogState(true)}>Overwrite</Button>}
        <Button
          variant="outlined"
          color="secondary"
          disabled={
            formState.queryName === editQueryForm.queryName &&
            formState.description === editQueryForm.description &&
            formState.saveType === editQueryForm.saveType
          }
          onClick={handleUpdate}
        >
          Save
        </Button>
      </>
    ) : (
      <>
        <Button
          variant="outlined"
          disabled={!formState.queryName}
          onClick={saveQuery}
        >
          Save
        </Button>
      </>
    );

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setOpenDialog("queryBuilder")}
        sx={{ display: "flex", flexDirection: "column", py: 0 }}
      >
        <Edit fontSize="small" />
        <Typography variant="caption" sx={{ fontSize: "10px" }}>
          Edit
        </Typography>
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        disabled={savedQueriesLoading}
        onClick={() => setOpenDialog("saveQuery")}
        sx={{ display: "flex", flexDirection: "column", py: 0 }}
      >
        {savedQueriesLoading ? (
          <CircularProgress size={20} sx={{ padding: .5 }} color="inherit" />
        ) : (
          <Save fontSize="small" />
        )}
        <Typography variant="caption" sx={{ fontSize: "10px" }}>
          Save
        </Typography>
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        disabled={savedQueriesLoading}
        onClick={() => setOpenDialog("savedQueries")}
        sx={{ display: "flex", flexDirection: "column", py: 0 }}
      >
        {savedQueriesLoading ? (
          <CircularProgress size={20} sx={{ padding: .5 }} color="inherit" />
        ) : (
          <Folder fontSize="small" />
        )}
        <Typography variant="caption" sx={{ fontSize: "10px" }}>
          Queries
        </Typography>
      </Button>

      {/* Query Builder modal */}
      <SimpleModal
        isOpen={openDialog === "queryBuilder"}
        handleClose={() => setOpenDialog(null)}
        actions={queryBuilderActions}
        title="Query Builder"
      >
        <>
          <Tabs
            value={currentTab}
            onChange={(e: SyntheticEvent, newValue: string) =>
              setCurrentTab(newValue)
            }
            variant="fullWidth"
            className="query-builder--tabs-container"
          >
            <Tab label="Query" value="query" />
            {pseudoMetricGroupArray.map(
              (x, i) =>
                i !== 0 && (
                  <Tab
                    label={`Metric Group${i > 1 ? ` ${i}` : ""}`}
                    value={`metricGroup${i}`}
                    key={`metricGroup${i}--tab`}
                  />
                )
            )}
            <Tab label="+ Metric Group" onClick={addMetricGroup} />
          </Tabs>
          <Box className="query-builder--form-area">
            <TabContent tabName="query" currentTab={currentTab}>
              <QueryForm metricGroupIndex={0} />
            </TabContent>
            {pseudoMetricGroupArray.map(
              (x, i) =>
                i !== 0 && (
                  <TabContent
                    tabName={`metricGroup${i}`}
                    currentTab={currentTab}
                    key={`metricGroup${i}--panel`}
                  >
                    <MetricGroupForm i={i} />
                  </TabContent>
                )
            )}
          </Box>
        </>
      </SimpleModal>

      {/* Saved Queries modal */}
      <SimpleModal
        isOpen={openDialog === "savedQueries"}
        handleClose={() => setOpenDialog(null)}
        title="Saved queries"
      >
        {savedQueriesLoading && (
          <Backdrop open={true} sx={{ position: 'absolute', zIndex: 2000, backgroundColor: "rgba(0,0,0,0.25)" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          direction="column"
          wrap="wrap"
          maxHeight={"calc(100vh - 164px)"}
          height={"500px"}
        >
          <RichTreeViewPro
            items={savedQueriesTree}
            slots={{ item: SavedQueryTreeItem }}
            disableSelection
          />
        </Grid>
      </SimpleModal>

      {/* Save query modal */}
      <SimpleModal
        isOpen={openDialog === "saveQuery" || openDialog === "editQuery"}
        handleClose={() => handleSaveQueryClose()}
        title={openDialog === "saveQuery" ? "Save query" : `Edit Query: ${editQueryForm.queryName}`}
        actions={saveEditActions}
      >
        <Box py={2}>
          <StyledTextField
            placeholder={"What would you like to name this query?"}
            label="Query Name"
            value={formState.queryName}
            // disabled={isSubmittingRequest}
            onChange={(e) => {
              setFormState({ ...formState, queryName: e.target.value });
            }}
          />
          <StyledTextArea
            placeholder={"Write a short description of what this query does"}
            label="Description"
            minRows={3}
            value={formState.description}
            // disabled={isSubmittingRequest}
            onChange={(e) => {
              setFormState({ ...formState, description: e.target.value });
            }}
          />
          <StyledRadioButtonsGroup
            id="saveType"
            value={formState.saveType}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormState({
                ...formState,
                saveType: e.target.value as "public" | "private" | "archived",
              });
            }}
            row={true}
          >
            <StyledRadioButton
              label="Public"
              value="public"
              textSize="sm"
              selected={formState.saveType === "public"}
            />
            <StyledRadioButton
              label="Private"
              value="private"
              textSize="sm"
              selected={formState.saveType === "private"}
            />
          </StyledRadioButtonsGroup>
          {overwriteError && (
            <Typography color="error" variant="caption">
              {overwriteError}
            </Typography>
          )}
        </Box>
      </SimpleModal>
      <SimpleModal
        isOpen={confirmDialogState}
        handleClose={resetDialogState}
        title={`Overwrite ${editQueryForm.editQueryName}`}
        actions={(
          <>
            <Button onClick={resetDialogState} color="secondary">Cancel</Button>
            <Button onClick={handleOverwriteQuery}>Overwrite</Button>
          </>
        )}
      >
        <Typography>
          Are you sure you want to overwrite <b>{editQueryForm.editQueryName}</b> with the current query? This cannot be undone.
        </Typography>
      </SimpleModal>
    </>
  );
}
