import { FormControl, InputLabel, TextareaAutosize } from "@mui/material";
import { ChangeEvent } from "react";
import "./styledTextArea.scss";

interface StyledTextAreaProps {
  disabled?: boolean;
  id?: string;
  label?: string;
  name?: string;
  value?: string;
  placeholder: string;
  minRows: number;
  hideLabel?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  initialValue?: string;
}

export const StyledTextArea = ({
  disabled,
  id,
  label,
  name,
  value,
  placeholder,
  minRows,
  hideLabel = false,
  onChange,
  ...props
}: StyledTextAreaProps) => {
  const labelMode = hideLabel ? "hideLabel-TextArea" : "label-TextArea";
  const fieldMode = hideLabel ? "simple-TextArea" : "full-TextArea";

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={id} shrink={true} className={labelMode} {...props}>
        {label}
      </InputLabel>
      <TextareaAutosize
        disabled={disabled}
        name={name}
        value={value}
        minRows={minRows}
        placeholder={placeholder}
        onChange={handleChange}
        className={fieldMode}
      />
    </FormControl>
  );
};
