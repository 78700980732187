import { Box, Stack, Theme, useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { Game } from "../../../models/Game"
import { getTeamFromString } from "../../../models/Team"

interface Props {
  game: Game
}

export default function GameDisplay({ game }: Props ) {
  const [loc, opp] = game.opponentInfo.split(" ")
  const oppTeam = getTeamFromString(opp)
  const isHome = loc === "vs"
  const day = dayjs(game.date)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Box className={`game-display--location-indicator ${isHome ? "home" : "away"}`}>
        {isHome ? "Home" : "Away"}
      </Box>
      <Box className={'game-display--date-indicator'}>
        <span className='bold'>
          {day.format('dddd')}
        </span>
        <span>
          {day.format('MMM D')}
        </span>
      </Box>
      <Box className='game-display--location-connector'>
        {loc}
      </Box>
      <Box className='game-display--opponent-indicator'>
        <Box
          component={oppTeam?.logo_src ? "img" : "div"}
          className="game-display--opponent-logo"
          src={oppTeam?.logo_src ?? ''}
          />
        {!isMobile &&
          <Stack alignItems='flex-start'>
            <span>{oppTeam.city}</span>
            <span className='bold'>{oppTeam.name}</span>
          </Stack>
        }
      </Box>
    </>
  )
}
