import "./explorerDataGrid.scss";
import { DataGridPremium, GridColDef, GridToolbar, gridClasses, GridColTypeDef, useGridApiRef, GridRowId, GridRowModel, GridActionsCellItem, gridStringOrNumberComparator, gridExpandedSortedRowIdsSelector } from "@mui/x-data-grid-premium";
import { TableData } from "../../../models/TableData";
import { useMemo } from "react";
import { isShotChartData, isVideoData } from "../../../models/TableData";
import VideoIcon from "../../Icons/VideoIcon";
import ShotChartIcon from "../../Icons/ShotChartIcon";
import ShotChartDialog from "../ShotChart/ShotChartDialog";
import { Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const slots = {
  toolbar: GridToolbar
}

function ShotChartCell({ data }: { data: string }) {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialog = () => setDialogIsOpen(!dialogIsOpen);

  return (
    <>
      <ShotChartDialog
        data={data}
        isOpen={dialogIsOpen}
        handleClose={toggleDialog}
      />
      <Box onClick={toggleDialog} className="cell--icon-container">
        <ShotChartIcon />
      </Box>
    </>
  );
}

export default function ExplorerDataGrid({ data, loading }: { data: TableData, loading: boolean }) {
  const apiRef = useGridApiRef();
  const [pinnedRowsIds, setPinnedRowsIds] = useState<{
    top: GridRowId[];
    bottom: GridRowId[];
  }> ({ top: [], bottom: [] });

  const { rows, pinnedRows } = useMemo(() => {
    const rowsData: GridRowModel[] = [];
    const pinnedRowsData: { top: GridRowModel[], bottom: GridRowModel[] } = { top: [], bottom: [] };
    const rowsWithIds = data.map((row, i) => Object.assign({ id: i }, row))

    rowsWithIds.forEach((row, i) => {
      if (pinnedRowsIds.top.includes(row.id)) {
        pinnedRowsData.top.push(row)
      } else if (pinnedRowsIds.bottom.includes(row.id)) {
        pinnedRowsData.bottom.push(row)
      } else {
        rowsData.push(row)
      }
    })

    return { rows: rowsData, pinnedRows: pinnedRowsData }
  }, [data, pinnedRowsIds]);

  const columns: GridColDef[] = useMemo(() => {
    const baseColDef: GridColDef = {
      field: "Rank",
      headerAlign: "center",
      headerClassName: "data-grid-header",
      align: "center",
      cellClassName: "data-grid-cell",
      width: 120,
    }

    const minColDef: GridColTypeDef = {
      sortable: false,
      filterable: false,
      groupable: false,
      disableColumnMenu: true,
      resizable: false,
      width: 90,
    }

    const shotChartColDef: GridColDef = {
      ...baseColDef,
      ...minColDef,
      field: "ShotChart",
      renderCell: (params) => {
        const coldata = params.value as string;
        return <ShotChartCell data={coldata} />;
      }
    }

    const videoColDef: GridColDef = {
      ...baseColDef,
      ...minColDef,
      field: "Video",
      renderCell: (params) => {
        const coldata = params.value as string;
        return (
          <a
            href={coldata}
            target="_blank"
            rel="noreferrer"
            className="cell--icon-container"
          >
            <VideoIcon />
          </a>
        );
      }
    }

    if (!data || data.length === 0) return [];
    const rankColumn: GridColDef = {
      ...baseColDef,
      ...minColDef,
      headerName: "Rank",
      type: "number",
      width: 60,
      valueGetter: (value, row) => {
        const sortedRowIds = gridExpandedSortedRowIdsSelector(apiRef);
        const index = sortedRowIds.indexOf(row.id);
        return index === -1 ? "" : index + 1;
      },

    }
    const actionColumn: GridColDef = {
      ...minColDef,
      field: 'actions',
      type: 'actions',
      width: 90,
      getActions: (params) => {
        const isPinnedTop = pinnedRowsIds.top.includes(params.id);
        const isPinnedBottom = pinnedRowsIds.bottom.includes(params.id);
        if (isPinnedTop || isPinnedBottom) {
          return [
            <GridActionsCellItem
              label="Unpin"
              icon={
                <Tooltip title="Unpin">
                  {isPinnedTop ? <ArrowDownward /> : <ArrowUpward />}
                </Tooltip>
              }
              onClick={() =>
                setPinnedRowsIds((prevPinnedRowsIds) => ({
                  top: prevPinnedRowsIds.top.filter(
                    (rowId) => rowId !== params.id,
                  ),
                  bottom: prevPinnedRowsIds.bottom.filter(
                    (rowId) => rowId !== params.id,
                  ),
                }))
              }
            />,
          ];
        }
        return [
          <GridActionsCellItem
              icon={
                <Tooltip title="Pin at the top">
                  <ArrowUpward />
                </Tooltip>
              }
              label="Pin at the top"
              onClick={() =>
                setPinnedRowsIds((prevPinnedRowsIds) => ({
                  ...prevPinnedRowsIds,
                  top: [...prevPinnedRowsIds.top, params.id],
                }))
              }
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Pin at the bottom">
                  <ArrowDownward />
                </Tooltip>
              }
              label="Pin at the bottom"
              onClick={() =>
                setPinnedRowsIds((prevPinnedRowsIds) => ({
                  ...prevPinnedRowsIds,
                  bottom: [...prevPinnedRowsIds.bottom, params.id],
                }))
              }
            />,
        ]
      }
    }

    const columnDef: GridColDef[] = Object.keys(data[0]).map((header) => {
      const colval = data[0][header]
      const isnum = colval === "NaN" || !isNaN(+colval) || (colval.endsWith('%') && !isNaN(parseFloat(colval)))

      if (isShotChartData(colval)) {
        return shotChartColDef
      } else if (isVideoData(colval)) {
        return videoColDef
      } else return {
        ...baseColDef,
        field: header,
        headerName: header,
        width: isnum ? 120 : 150,
        type: isnum ? "number" : "string",
        sortComparator: !isnum ? gridStringOrNumberComparator : (v1, v2) => {
          const n1 = parseFloat(v1)
          const n2 = parseFloat(v2)
          if (isNaN(n1) && isNaN(n2)) return 0
          if (isNaN(n1)) return -1
          if (isNaN(n2)) return 1
          return n1 - n2
        },
        valueFormatter: (value, row) => value,
      };
    });
    return [actionColumn, rankColumn].concat(columnDef);
  }, [data, apiRef, pinnedRowsIds.top, pinnedRowsIds.bottom]);


  return (
    <div className="data-grid-container">
      <DataGridPremium
        loading={loading}
        rows={rows}
        columns={columns}
        initialState={{
          sorting: { sortModel: [{ field: "Poss", sort: "desc" }] },
          pinnedColumns: { left: ['Rank'], right: ['actions'] },
        }}
        pinnedRows={pinnedRows}
        headerFilterHeight={50}
        rowHeight={40}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'data-grid-row even' : 'data-grid-row odd'}
        slots={slots}
        slotProps={{
          toolbar: {
            csvOptions: { getRowsToExport: () => gridExpandedSortedRowIdsSelector(apiRef) },
            excelOptions: { getRowsToExport: () => gridExpandedSortedRowIdsSelector(apiRef) },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
          }
        }}
        density="compact"
        columnHeaderHeight={50}
        disableAggregation
        disableDensitySelector
        hideFooter
        sortingOrder={['desc', 'asc']}
        apiRef={apiRef}
        ignoreDiacritics
        sx={{
          [`& .${gridClasses.columnSeparator}`]: {
            visibility: 'visible',
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none',
            },
          },
          '.MuiDataGrid-columnHeader, .MuiDataGrid-filler' : {
            backgroundColor: '#F5F5F5',
            'borderBottom': "3px solid black",
          },
          '.MuiDataGrid-iconButtonContainer': {
            width: 'auto !important',
            order: -1,
          },
          '.MuiDataGrid-sortIcon': {
            color: '#292929',
          },
          '.MuiDataGrid-menuIcon': {
            width: 'auto !important',
          },
        }}
      />
    </div>
  );
}
