import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { Metric, metricGroupPresets } from "../../../models/Explorer";
import { useExplorerStore } from "../../../data/ExplorerStore";
import QueryForm from "./QueryForm";
import { StyledCheckbox } from "../../Elements/StyledCheckbox/StyledCheckbox";

interface Props {
  i: number;
}

export default function MetricGroupForm({ i }: Props) {
  const metrics = useExplorerStore(({ metrics }) => metrics);
  const metricGroup = useExplorerStore(({ metricGroups }) => metricGroups[i]);
  const updateMetricGroup = useExplorerStore(
    ({ updateMetricGroup }) => updateMetricGroup
  );
  const metricGroupForUpdate = Object.assign({}, metricGroup);

  function onMetricsChange(
    event: ChangeEvent<unknown>,
    value: string[] | null
  ) {
    const newMetrics =
      value && value.length
        ? value?.map((m) => ({ value: m, label: m }))
        : undefined;
    metricGroupForUpdate.metrics = newMetrics;
    updateMetricGroup(i, metricGroupForUpdate);
  }

  function onRatioChange(event: ChangeEvent<unknown>, value: Metric | null) {
    metricGroupForUpdate.ratio = value;
    updateMetricGroup(i, metricGroupForUpdate);
  }

  function addMetric(metric: Metric) {
    const included = metricGroupForUpdate.metrics?.some(
      (m) => m.value === metric.value
    );
    if (!included) {
      if (!metricGroupForUpdate.metrics?.length) {
        metricGroupForUpdate.metrics = [metric];
      } else {
        metricGroupForUpdate.metrics = [
          ...metricGroupForUpdate.metrics,
          metric,
        ];
      }
    }
  }

  function removeMetric(metric: Metric) {
    const index = metricGroupForUpdate.metrics?.findIndex(
      (m) => m.value === metric.value
    );
    if (index && index !== -1) {
      const metricsForUpdate = metricGroupForUpdate.metrics?.slice() ?? [];
      metricsForUpdate?.splice(index, 1);
      metricGroupForUpdate.metrics = metricsForUpdate;
    }
  }

  function presetSelect(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const metrics = JSON.parse(event.target.value) as Metric[];
    if (checked) {
      metrics.forEach((metric) => addMetric(metric));
    } else {
      metrics.forEach((metric) => removeMetric(metric));
    }
    updateMetricGroup(i, metricGroupForUpdate);
  }

  return (
    <>
      <Box mb={2}>
        <Typography mb={2} fontSize={18}>
          Metrics
        </Typography>
        <Autocomplete
          onChange={onMetricsChange}
          id={`metricGroup${i}--metric-select`}
          multiple
          options={metrics}
          value={metricGroupForUpdate.metrics?.map ? metricGroupForUpdate.metrics.map((m) => m.value) : []}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          blurOnSelect={false}
          sx={{ minWidth: "200px" }}
        />
        <Box mx={1}>
          {metricGroupPresets.map((preset) => {
            const checked = preset.metrics.every((m) =>
              metricGroupForUpdate.metrics?.some ? metricGroupForUpdate.metrics.some((m2) => m.value === m2.value) : false
            );
            return (
              <StyledCheckbox
                key={JSON.stringify(preset)}
                label={preset.label}
                value={JSON.stringify(preset.metrics)}
                onChange={presetSelect}
                size="small"
                checked={checked}
              />
            );
          })}
        </Box>
      </Box>
      <QueryForm metricGroupIndex={i} />
      <Box mb={2}>
        <Typography mb={2} fontSize={18}>
          Ratio
        </Typography>
        <Autocomplete
          onChange={onRatioChange}
          id={`metricGroup${i}--ratio-select`}
          options={metricGroupForUpdate.metrics ?? []}
          value={metricGroupForUpdate.ratio ?? undefined}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Select" />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          sx={{ width: "200px" }}
        />
      </Box>
    </>
  );
}
