import { Typography, Stack, Avatar } from "@mui/material";
import { Goal } from "../../../models/IdReport";
import { convertPlayerName } from "../../../util/strings";

export default function CrashGoal({
  goal,
  loading,
}: {
  goal: Goal;
  loading: boolean;
}) {
  const headshotSrc = `https://cdn.nba.com/headshots/nba/latest/1040x760/${goal.nba_id}.png`;
  return (
    <Stack direction="row" mb={2} alignItems="center">
      <Avatar
        src={headshotSrc}
        className="id-report--player-headshot"
        sx={{ height: "55px", width: "55px", marginRight: "16px" }}
      ></Avatar>
      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
      >
        <Typography
          fontSize="18px"
          variant="h5"
          textTransform="uppercase"
          fontWeight="medium"
        >
          {convertPlayerName(goal.name)}
        </Typography>
        <CrashGoalDetails goal={goal} loading={loading} />
      </Stack>
    </Stack>
  );
}

export function TeamCrashGoal({
  goal,
  loading,
}: {
  goal?: Goal;
  loading: boolean;
}) {
  if (!goal) return null;
  return (
    <Stack direction="row" alignItems="center" mt={2} mb={4}>
      <img
        src="/images/blazers-logo-black.png"
        alt="Trail Blazers Logo"
        height="43px"
        width="auto"
        style={{ marginRight: "16px" }}
      />
      <Stack>
        <Typography
          fontSize="18px"
          variant="h5"
          textTransform="uppercase"
          fontWeight="medium"
        >
          Trail Blazers
        </Typography>
        <CrashGoalDetails goal={goal} loading={loading} />
      </Stack>
    </Stack>
  );
}

export function CrashGoalDetails({
  goal,
  loading,
}: {
  goal: Goal;
  loading: boolean;
}) {
  const targetAsNum = parseFloat(goal.target);
  const secondaryTargetAsNum = parseFloat(goal.secondaryTarget);
  const outcomeAsNum = parseFloat(goal.outcome);
  const goalIsMet =
    goal.comparator === ">"
      ? outcomeAsNum >= targetAsNum
      : outcomeAsNum <= targetAsNum;

  const secondaryGoalIsMet =
    goal.comparator === ">"
      ? outcomeAsNum >= secondaryTargetAsNum
      : outcomeAsNum <= secondaryTargetAsNum;

  const goalClass = (() => {
    if (loading) {
      return "";
    } else if (goalIsMet) {
      return "goal--met";
    } else if (secondaryGoalIsMet) {
      return "secondary-goal--met";
    } else return "goal--not-met";
  })();

  return (
    <>
      <Typography
        fontWeight={loading ? "regular" : "medium"}
        fontFamily="Roboto"
        className={goalClass}
        fontSize="20px"
      >
        {loading ? "--" : Math.round(outcomeAsNum * 100)}%{" "}
        <Typography component="span" color="black" fontSize="20px">
          | {"> "}
          {loading ? "--" : Math.round(targetAsNum * 100)}%
        </Typography>
      </Typography>
      <Typography fontSize="15px" fontWeight="bold" color="#a0a0a0" mt={-0.25}>
        {loading ? "--" : goal.sampleSize} {goal.sampleSizeLabel}
      </Typography>
    </>
  );
}
