import {
  TreeViewBaseItem,
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Root,
  useTreeItem2Utils,
  TreeItem2DragAndDropOverlay,
  useTreeItem2,
} from "@mui/x-tree-view-pro";
import { EditQueryForm, SavedQuery, SavedQueryForm } from "../../../models/Explorer";
import { TreeItem2Props } from "@mui/x-tree-view/TreeItem2";
import React, { useState } from "react";
import { Avatar, Box, Button, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import HandIcon from "@mui/icons-material/PanTool";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import dayjs from "dayjs";
import { useExplorerStore } from "../../../data/ExplorerStore";
import { SimpleModal } from "../../Elements/SimpleModal/SimpleModal";

const BLITZ_ASSETS_BUCKET_BASE_URL =
  "https://blitz-assets-bucket.s3.us-west-2.amazonaws.com";

export function getUserHeadshotUrl(userId: string): string {
  return `${BLITZ_ASSETS_BUCKET_BASE_URL}/scouts/${userId}.png`;
}

type TreeItemType = {
  id: string;
  label: string;
  savedQuery: SavedQuery | null;
  type: "folder" | "query";
  orphan: boolean;
  handleClose?: () => void;
  openEditDialog?: (queryForm: SavedQueryForm, editQueryForm: EditQueryForm) => void;
}

export type SavedQueryTreeItemType = TreeViewBaseItem<TreeItemType>

interface CustomFolderLabelProps {
  className: string;
  childrenCount: number;
  item: SavedQueryTreeItemType;
}

interface CustomQueryLabelProps {
  className: string;
  item: SavedQueryTreeItemType;
}

const CustomFolderLabel = ({ item, className, childrenCount }: CustomFolderLabelProps) => {
  const label = item.orphan ? "No Owner" : item.label;
  const [given_name, family_name] = item.label.split(" ") ?? ["", ""];
  const headshotUrl = getUserHeadshotUrl(item.id);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={4}
      flexGrow={1}
      className={className}
    >
      <Stack direction="row" spacing={1}>
        {!item.orphan && (
          <Avatar
            alt={`${given_name} ${family_name}`}
            title={`${given_name} ${family_name}`}
            src={headshotUrl}
            sx={{ width: 24, height: 24 }}
          >
            <Typography>
              {given_name?.[0] ?? ""}
              {family_name?.[0] ?? ""}
            </Typography>
          </Avatar>
        )}
        <Typography>{label}</Typography>
      </Stack>
      <Chip label={childrenCount} size="small" />
    </Stack>
  );
}

const FolderTreeItem = React.forwardRef(function (props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) {
  const {
    getRootProps,
    getContentProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2(props);

  const { interactions } = useTreeItem2Utils({ itemId: props.itemId, children: props.children });

  const handleClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  const childrenCount = (publicAPI as any).getItemOrderedChildrenIds(props.itemId).length;
  const item = (publicAPI as any).getItem(props.itemId)

  return (
    <Box>
      <TreeItem2Root {...getRootProps({ sx: { position: "relative" } })}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
            width: "28px",
            height: "calc(100% - 12px)",
            position: "absolute",
            left: "-28px",
            top: "2px",
          }}
        >
          {status.expanded ? (
            <>
              <IconButton
                onClick={handleClick}
                aria-label="collapse item"
                size="small"
                sx={{ padding: "2px 5px", marginRight: "5px" }}
              >
                <FolderOpenIcon sx={{ fontSize: "24px" }} />
              </IconButton>
              <Box sx={{ flexGrow: 1, borderLeft: "1px dashed" }} />
            </>
          ) : (
            <IconButton
              onClick={handleClick}
              aria-label="expand item"
              size="small"
              sx={{ padding: "2px 5px", marginRight: "5px" }}
            >
              <FolderIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          )}
        </Box>
        <TreeItem2Content {...getContentProps()}>
          <CustomFolderLabel
            {...{ item, className: "" as string, childrenCount }}
          />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </TreeItem2Content>
        {props.children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </Box>
  );
})

interface ConfirmDialogState {
  open: boolean;
  id?: string;
  name?: string;
}

const CustomQueryLabel = ({ item, className, }: CustomQueryLabelProps) => {
  const saveDate = dayjs(item.savedQuery?.LAST_SAVED).format("MMM D, YYYY");
  const [confirmDialogState, setConfirmDialogState] =
    useState<ConfirmDialogState>({ open: false });
  const loadSavedQuery = useExplorerStore(
    ({ loadSavedQuery }) =>
      (id: string) => {
        loadSavedQuery(id);
        item.handleClose && item.handleClose();
      }
  );
  const archiveQuery = useExplorerStore(({ archiveQuery }) => (id: string) => {
    setConfirmDialogState({ open: false });
    archiveQuery(id);
  });
  const claimQuery = useExplorerStore(({ claimQuery }) => (id: string) => {
    claimQuery(id);
  });
  const handleEdit = () => {
    const st = item.savedQuery?.SAVE_TYPE as "public" | "private" | "archived";
    const queryForm = {
      description: item.savedQuery?.DESCRIPTION ?? "",
      saveType: st,
      queryName: item.label,
    };
    const editQueryForm: EditQueryForm = Object.assign({}, queryForm, {
      editQuery: item.savedQuery?.QUERY,
      editQueryName: item.label,
      editQueryId: item.id,
    });
    item.openEditDialog && item.openEditDialog(queryForm, editQueryForm);
  }
  const resetDialogState = () => setConfirmDialogState({ open: false, id: undefined, name: undefined });

  const archiveDialogActions = (
    <>
      <Button onClick={resetDialogState} color="secondary">Cancel</Button>
      <Button
        onClick={() => {
          archiveQuery(confirmDialogState.id ?? "");
        }}
        color="primary"
      >
        Archive
      </Button>
    </>
  )
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
        flexGrow={1}
        className={className}
      >
        <Stack>
          <Stack direction="row" spacing={0.3} alignItems="center">
            <Typography
              onClick={() => loadSavedQuery(item.id)}
              sx={{ ":hover": { cursor: "pointer" } }}
            >
              {item.label}
            </Typography>
            {item.savedQuery?.DESCRIPTION && (
              <Tooltip title={item.savedQuery.DESCRIPTION}>
                <HelpOutlineOutlined fontSize="inherit" />
              </Tooltip>
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="caption"
              color="text.secondary"
            >{`${saveDate}`}</Typography>
            {item.savedQuery?.SAVE_TYPE === "private" && (
              <Typography variant="caption" color="primary">
                {"(Private)"}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1}>
          {(item.orphan || item.savedQuery?.IS_OWNER) && (
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                setConfirmDialogState({
                  open: true,
                  id: item.id,
                  name: item.label,
                });
              }}
            >
              <Tooltip id="archive-button" title="Archive">
                <ArchiveIcon color="disabled" fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
          {item.savedQuery?.IS_OWNER && (
            <IconButton size="small" color="secondary" onClick={handleEdit}>
              <Tooltip id="edit-button" title="Edit">
                <EditIcon color="disabled" fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
          {item.orphan && (
            <IconButton size="small" color="secondary" onClick={() => claimQuery(item.id)}>
              <Tooltip id="claim-button" title="Claim">
                <HandIcon color="disabled" fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
        </Stack>
      </Stack>
      <SimpleModal
        isOpen={confirmDialogState.open}
        handleClose={resetDialogState}
        title={`Archive Query`}
        actions={archiveDialogActions}
      >
        <Typography>
          Are you sure you want to archive <b>{confirmDialogState.name}</b>? This cannot be undone.
        </Typography>
      </SimpleModal>
    </>
  );
}

const QueryTreeItem = React.forwardRef(function (props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) {
  const {
    getRootProps,
    getContentProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    publicAPI,
  } = useTreeItem2(props);
  const item = (publicAPI as any).getItem(props.itemId)

  return (
    <Box>
      <TreeItem2Root {...getRootProps({ sx: { position: "relative" } })}>
        <TreeItem2Content {...getContentProps()} sx={{ backgroundColor: 'inherit', ':hover': { backgroundColor: 'inherit', cursor: 'inherit' } }}>
          <CustomQueryLabel
            {...{ item, className: "" as string }}
          />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </TreeItem2Content>
        {props.children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </Box>
  );
})

function SavedQueryTreeItem(props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) {
  const { publicAPI } = useTreeItem2(props);
  const item = (publicAPI as any).getItem(props.itemId)
  return item.type === "folder" ? (
    <FolderTreeItem {...props} />
  ) : (
    <QueryTreeItem {...props} />
  );
}

export default React.forwardRef(SavedQueryTreeItem);
